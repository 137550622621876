<template>
  <div>
    <b-overlay
      :show="showSeance"
      rounded="sm"
      no-fade
    >
      <b-row>
        <b-col
          lg="12"
        >
          <b-card
            class="mb-1"
          >
            <b-card-header><h3 class="text-muted">
              Seance
            </h3></b-card-header>
            <b-card-body v-if="showSeance === false">
              <b-row>
                <b-col
                  lg="6"
                >
                  <b-list-group flush>
                    <b-list-group-item>
                      Company: {{ seanceData.seance.company_id.uid }}
                    </b-list-group-item>
                    <b-list-group-item>
                      Trainee: {{ seanceData.seance.trainee_id.uid }}
                    </b-list-group-item>
                    <b-list-group-item>
                      Trainee PC: {{ seanceData.seance.trainee_pc }}
                    </b-list-group-item>
                    <b-list-group-item>
                      Premium Type: {{ seanceData.seance.premium_type.premium_type }}
                    </b-list-group-item>
                    <b-list-group-item
                      v-if="seanceData.seance.status ==='A'"
                    >
                      Premium Hours: {{ seanceData.seance.total_premium_hours }} h
                    </b-list-group-item>
                    <b-list-group-item
                      v-if="seanceData.seance.status ==='C'"
                    >
                      Premium Hours: 0 h
                    </b-list-group-item>
                    <b-list-group-item
                      v-if="seanceData.seance.status ==='A'"
                    >
                      Total Amount: {{ totalAmountEur }} €
                    </b-list-group-item>
                    <b-list-group-item
                      v-if="seanceData.seance.status ==='C'"
                    >
                      Total Amount: 0 €
                    </b-list-group-item>
                    <b-list-group-item>
                      Status: {{ getStatus }}
                    </b-list-group-item>
                    <b-list-group-item v-if="seanceData.seance.status ==='C' && seanceData.replaced_seance_id != null">
                      The seance was replaced by <a @click="showSeanceInfo(seanceData.replaced_seance_id)"><b>'{{ seanceData.replaced_transaction_uid }}'</b></a>
                    </b-list-group-item>
                  </b-list-group>
                </b-col>
                <b-col
                  lg="6"
                >
                  <b-list-group flush>
                    <b-list-group-item>
                      Seance Duration: {{ seanceData.seance.seance_duration }} h
                    </b-list-group-item>
                    <b-list-group-item>
                      Seance Date: {{ formatDate(seanceData.seance.seance_date) }}
                    </b-list-group-item>
                    <b-list-group-item>
                      Created On: {{ formatDateTime(seanceData.seance.created_on) }}
                    </b-list-group-item>
                    <b-list-group-item>
                      Updated On: {{ formatDateTime(seanceData.seance.updated_on) }}
                    </b-list-group-item>
                    <b-list-group-item>
                      Seance Uid: {{ seanceData.seance.seance_uid }}
                    </b-list-group-item>
                    <b-list-group-item
                      v-if="seanceData.seance.is_sync === true"
                    >
                      Sync On: {{ formatDateTime(seanceData.seance.sync_on) }}
                    </b-list-group-item>
                    <b-list-group-item>
                      Transaction Uid: {{ seanceData.seance.transaction_uid }}
                    </b-list-group-item>
                  </b-list-group>
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <!-- modal vertical center -->
        <b-modal
          id="modal-center"
          centered
          title="Rule Details"
          ok-only
          ok-title="Close"
        >
          <b-card-text>
            <b-list-group flush>
              <b-list-group-item>
                Rule ID: {{ rule_details.id }}
              </b-list-group-item>
              <b-list-group-item>
                Period: {{ rule_details.reference_period }}
              </b-list-group-item>
              <b-list-group-item>
                Premium Type: {{ rule_details.premium_type }}
              </b-list-group-item>
              <b-list-group-item>
                Trainee PC: {{ rule_details.trainee_pc }}
              </b-list-group-item>
              <b-list-group-item v-if="rule_details.overspill_allowed">
                Overspill Destination Premium Type: {{ rule_details.overspill_destination_premium_type }}
              </b-list-group-item>
              <b-list-group-item v-if="!rule_details.overspill_allowed">
                Overspill: No
              </b-list-group-item>
              <b-list-group-item>
                Limit Scope: {{ getLimitScope(rule_details.limit_scope) }}
              </b-list-group-item>
              <b-list-group-item>
                Value: {{ (rule_details.value/100) }} EUR
              </b-list-group-item>
              <b-list-group-item>
                Limit: {{ rule_details.limit_hours }} h
              </b-list-group-item>
              <b-list-group-item>
                Status: {{ getRuleStatus(rule_details.rule_status) }}
              </b-list-group-item>
            </b-list-group>
          </b-card-text>
        </b-modal>
        <b-col
          lg="6"
        >
          <b-card
            v-if="seanceData.seance.status ==='A'"
            class="mb-1 card-employee-task"
          >
            <b-card-header>
              <h3 class="text-muted">
                Premium granted
              </h3>
            </b-card-header>

            <!-- body -->
            <b-card-body>
              <div v-if="seanceData.seance && seanceData.premiums.length==0">
                No premiums found.
              </div>
              <div v-if="seanceData.premiums.length > 0">
                A total of {{ totalAmountEur }} EUR was granted.
              </div>
              <div
                v-if="seanceData.premiums.length > 0"
                class="mb-1"
              >
                Details:
              </div>
              <div
                v-for="(premium, index) in seanceData.premiums"
                :key="premium.key"
                class="employee-task d-flex justify-content-between align-items-center w-100"
              >
                <div class="text-wrap w-100">{{ (index+1) }}) Rule<a
                  id="premiums-rule-link"
                  v-b-modal.modal-center
                  href="javascript:void(0)"
                  @click="setRuleDetails(premium.rule_info)"
                >#{{ premium.rule_id }}</a>for {{ premium.trainee_pc }}: {{ premium.hours }} hours of premium type {{ premium.premium_type }}</div>
              </div>
            </b-card-body>
          </b-card>
        </b-col>
        <b-col
          lg="6"
        >
          <b-card
            v-if="seanceData.seance.status ==='A'"
            class="mb-1 card-employee-task"
          >
            <b-card-header>
              <h3 class="text-muted">
                Premium denied
              </h3>
            </b-card-header>
            <!-- body -->
            <b-card-body>
              <div v-if="seanceData.seance && seanceData.rejections.length==0">
                No rejections found.
              </div>
              <div v-if="seanceData.rejections.length > 0">
                Some premiums were denied
              </div>
              <div
                v-if="seanceData.rejections.length > 0"
                class="mb-1"
              >
                Details:
              </div>
              <div
                v-for="(rejection, index) in seanceData.rejections"
                :key="rejection.key"
                class="employee-task d-flex justify-content-between align-items-center w-100"
              >
                <div class="text-wrap w-100">{{ (index+1) }}) Rule<a
                  id="rejections-rule-link"
                  v-b-modal.modal-center
                  href="javascript:void(0)"
                  @click="setRuleDetails(rejection.rule_info)"
                >#{{ rejection.rule_id }}</a>for {{ rejection.trainee_pc }}: {{ rejection.hours }} hours rejected, because {{ formatReason(rejection.reason) }}</div>
              </div>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </b-overlay>
  </div>
</template>

<script>
import {
  BCard, BCardBody, BCardHeader, BRow, BCol, BCardText,
  BOverlay,
  BListGroup,
  BListGroupItem,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { premiumSeances } from '@/views/pages/seances/premiumSeances'

export default {
  name: 'SeanceSidebar',
  components: {
    BCard,
    BCardBody,
    BCardHeader,
    BCardText,
    BOverlay,
    BRow,
    BCol,
    BListGroup,
    BListGroupItem,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: ['isSeanceSidebarActive', 'seanceId'],
    event: 'update:is-seance-sidebar-active',
  },
  props: {
    isSeanceSidebarActive: {
      type: Boolean,
      required: true,
    },
    seanceId: {
      type: Number,
      required: true,
      default: () => 0,
    },
  },
  data() {
    return {
      rule_details: '',
      showSeance: true,
      errorsMessage: '',
      seanceData: {
        seance: {
          status: 'A',
          total_amount: 0,
        },
        premiums: [],
        rejections: [],
      },
    }
  },
  computed: {
    totalAmountEur() {
      return (this.seanceData.seance.total_amount / 100)
    },
    getStatus() {
      if (this.seanceData.seance.status === 'C') {
        return 'Cancelled'
      }
      return 'Active'
    },
  },
  mounted() {
    if (this.seanceId > 0) {
      this.seanceData = {
        seance: {
          status: 'A',
          total_amount: 0,
        },
        premiums: [],
        rejections: [],
      }
      const { fetchSeance } = premiumSeances()
      fetchSeance(this.seanceId)
        .then(response => {
          this.seanceData = response.data.data
          this.showSeance = false
        })
    }
  },
  methods: {
    getRuleStatus(scope) {
      if (scope === 'A') {
        return 'Active'
      } if (scope === 'I') {
        return 'Inactive'
      }
      return '-'
    },
    getLimitScope(scope) {
      if (scope === 'INDIV') {
        return 'Individual'
      } if (scope === 'COMP') {
        return 'Company'
      }
      return '-'
    },
    setRuleDetails(rule) {
      this.rule_details = rule
    },
    formatReason(str) {
      const pattern = /_/g
      return str.replace(pattern, ' ').toLowerCase()
    },
    showSeanceInfo(id) {
      this.showSeance = true
      const { fetchSeance } = premiumSeances()
      fetchSeance(id)
        .then(response => {
          this.seanceData = response.data.data
          this.showSeance = false
        })
    },
    getEuroAmount(amount) {
      return (amount / 100)
    },
    formatDate(date) {
      return new Date(date).toISOString().slice(0, 10)
    },
    formatDateTime(date) {
      const dateTime = new Date(date).toISOString().slice(0, 16).split('T')
      const dateText = dateTime[0]
      const timeText = dateTime[1]
      return dateText.concat(' ', timeText)
    },
  },
}
</script>
<style type="text/css">
#premiums-rule-link:link { color: #0c141c; font-weight: bold; padding-left: 5px; padding-right: 5px }
#premiums-rule-link:visited { color: #0c141c; }

#rejections-rule-link:link { color: #0c141c; font-weight: bold; padding-left: 5px; padding-right: 5px }
#rejections-rule-link:visited { color: #0c141c; }
</style>
