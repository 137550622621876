<template>
  <div>

    <b-card
      class="mb-4"
    >
      <b-card-text>
        <b-row>
          <b-col
            cls="12"
            md="10"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <b-form-select
              v-model="referencePeriod"
              :options="referencePeriodOptions"
              class="width-20-per mr-1"
              @change="reloadPage()"
            />
            <b-form-select
              v-model="premiumStatus"
              :options="premiumStatusOptions"
              class="width-20-per mr-1"
              @change="reloadPage()"
            />
            <b-form-select
              v-model="syncStatus"
              :options="syncStatusOptions"
              class="width-15-per mr-1"
              @change="reloadPage()"
            />
            <b-button
              variant="primary"
              class="btn-icon"
              @click="reloadPage()"
            >
              <feather-icon icon="RotateCwIcon" />
            </b-button>
            <b-button
              variant="primary"
              class="btn-icon ml-1"
              @click="resetPage()"
            >
              Reset
            </b-button>
            <label class="ml-1">Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
            <b-spinner
              v-if="show"
              class="ml-1"
              label="Loading..."
            />
          </b-col>
          <!-- Search -->
          <b-col
            cols="12"
            md="4"
          >
            <div class="d-flex align-items-center justify-content-end" />
          </b-col>
          <b-col
            cls="12"
            class="mt-2"
          >
            <!-- input search -->
            <vue-good-table
              id="seanceTbl"
              :columns="columns"
              :rows="itemList"
              :rtl="direction"
              :search-options="{
                enabled: true,
                externalQuery: filterQ,
                trigger: 'enter',
                skipDiacritics: true,
              }"
              :pagination-options="{
                enabled: false,
                perPage:perPage
              }"
              striped
              responsive
              hover
              theme="polar-bear"
              mode="remote"
              @on-row-click="onRowSelected"
              @on-sort-change="onSortChange"
              @on-column-filter="onColumnFilter"
            >
              <template
                slot="table-row"
                slot-scope="props"
              >
                <div
                  v-if="props.column.field === 'company_id'"
                  class="text-nowrap"
                >
                  <span class="text-nowrap">{{ formatCompany(props.row.company_id) }}</span>
                </div>
                <div
                  v-else-if="props.column.field === 'seance_uid'"
                  class="text-nowrap"
                >
                  <div
                    v-if="props.row.status === 'C'"
                  ><b-avatar
                    v-b-tooltip.hover
                    title="Deleted"
                    variant="secondary"
                    size="32px"
                  >
                    <feather-icon icon="Trash2Icon" />
                  </b-avatar> {{ props.row.seance_uid }}</div>
                  <div
                    v-else-if="props.row.premium_status === 'G'"
                  >
                    <b-avatar
                      v-b-tooltip.hover
                      title="Granted"
                      variant="success"
                      size="32px"
                    >
                      <feather-icon icon="CheckIcon" />
                    </b-avatar>
                    {{ props.row.seance_uid }}
                  </div>
                  <div
                    v-else-if="props.row.premium_status === 'R'"
                  ><b-avatar
                     v-b-tooltip.hover
                     title="Rejected"
                     variant="danger"
                     size="32px"
                   >
                     <feather-icon icon="XIcon" />
                   </b-avatar>
                    {{ props.row.seance_uid }}</div>
                  <div
                    v-else-if="props.row.premium_status === 'B'"
                  >
                    <b-avatar-group size="32px">
                      <b-avatar
                        v-b-tooltip.hover
                        title="Granted"
                        class="pull-up"
                        variant="success"
                      >
                        <feather-icon icon="CheckIcon" />
                      </b-avatar>
                      <b-avatar
                        v-b-tooltip.hover
                        title="Rejected"
                        class="pull-up"
                        variant="danger"
                      >
                        <feather-icon icon="XIcon" />
                      </b-avatar><span class="ml-1">{{ props.row.seance_uid }}</span>
                    </b-avatar-group>
                  </div>
                  <div v-else>
                    {{ props.row.seance_uid }}
                  </div>
                </div>
                <div
                  v-else-if="props.column.field === 'is_sync'"
                />
                <div
                  v-else-if="props.column.field === 'premium_type'"
                  class="text-nowrap"
                >
                  <span class="text-nowrap">{{ props.row.premium_type.premium_type }}</span>
                </div>
                <div
                  v-else-if="props.column.field === 'trainee_id'"
                  class="text-nowrap"
                >
                  <span class="text-nowrap">{{ formatTrainee(props.row.trainee_id) }}</span>
                </div>
                <div
                  v-else-if="props.column.field === 'seance_date'"
                  class="text-nowrap"
                >
                  <span class="text-nowrap">{{ formatDate(props.row.seance_date) }}</span>
                </div>
                <div
                  v-else-if="props.column.field === 'seance_duration'"
                  class="text-nowrap"
                >
                  <span class="text-nowrap">{{ props.row.seance_duration }}</span>
                </div>
                <div
                  v-else-if="props.column.field === 'created_on'"
                >
                  <span class="text-nowrap">{{ formatDateTime(props.row.created_on) }} </span>
                </div>
                <!-- Column: Actions -->
                <span v-else-if="props.column.field === 'actions'">
                  <a @click="onRowSelected({ id: props.row.id })">
                    <feather-icon icon="InfoIcon" />
                    <span class="align-middle ml-50">Info</span>
                  </a>
                </span>

                <!-- Column: Common -->
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>

              <!-- pagination -->
              <template
                slot="pagination-bottom"
              >
                <div class="d-flex justify-content-between flex-wrap">
                  <div />
                </div>
              </template>
            </vue-good-table>
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              align="right"
              size="sm"
              class="my-0 mt-2"
              first-text="First"
              prev-text="Prev"
              next-text="Next"
              last-text="Last"
              @click="goToPage()"
            />
          </b-col>
        </b-row>
      </b-card-text>
    </b-card>

    <b-sidebar
      id="rule-sidebar-right"
      v-model="seanceEditVisible"
      bg-variant="white"
      right
      backdrop
      shadow
      no-header
      :style="cssVars"
    >
      <seance-sidebar
        v-if="seanceEditVisible === true"
        :seance-id="selectedRowId"
        :is-seance-sidebar-active.sync="seanceEditVisible"
        :visible="isSeanceSidebarActive"
        @clicked="onClickChild"
      />
    </b-sidebar>
  </div>
</template>

<script>
import router from '@/router'
import { VueGoodTable } from 'vue-good-table'
import {
  VBTooltip,
  BAvatarGroup,
  BAvatar,
  BCard,
  BCardText,
  BRow,
  BCol,
  BPagination,
  BSidebar,
  VBToggle,
  BSpinner, BFormSelect, BButton,
  // BOverlay,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import store from '@/store'
// import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { premiumSeances } from './premiumSeances'
import SeanceSidebar from './seanceSidebar.vue'

export default {
  name: 'SearchList',
  components: {
    BAvatarGroup,
    BAvatar,
    VueGoodTable,
    BCard,
    BCardText,
    BRow,
    BCol,
    vSelect,
    BPagination,
    BSidebar,
    BSpinner,
    // BOverlay,
    SeanceSidebar,
    BFormSelect,
    BButton,
  },
  directives: {
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      myTitle: 'abc',
      referencePeriod: 1,
      referencePeriodCommon: 1,
      referencePeriodOptions: [
      ],
      columnSort: {
        type: 'desc',
        field: 'created_on',
      },
      columnFilters: {},
      keyTyping: false,
      premiumTypeOptions: [],
      columns: [
        {
          field: 'is_sync',
          sortable: false,
          tdClass: this.syncTdClass,
          width: '0.4em',
        },
        {
          label: 'Seance UID',
          field: 'seance_uid',
          filterOptions: {
            enabled: true,
            placeholder: 'Search UID',
            // trigger: 'enter', // only trigger on enter not on keyup
          },
          tdClass: 'text-left',
          width: '220px',
        },
        {
          label: 'Company UID',
          field: 'company_id',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Company UID',
          },
          tdClass: 'text-left',
          width: '160px',
        },
        {
          label: 'Trainee ID',
          field: 'trainee_id',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Trainee ID',
          },
          tdClass: 'text-left',
          width: '180px',
        },
        {
          label: 'Trainee PC',
          field: 'trainee_pc',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Trainee PC',
          },
          tdClass: 'text-left',
          width: '140px',
        },
        {
          label: 'Premium Type',
          field: 'premium_type',
          filterOptions: {
            enabled: true,
            placeholder: 'Any',
            filterDropdownItems: this.premiumTypeOptions,
          },
          tdClass: 'text-left',
          width: '160px',
        },
        {
          label: 'Duration(H)',
          field: 'seance_duration',
          type: 'number',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Seance duration',
          },
          tdClass: 'text-left',
          width: '135px',
        },
        {
          label: 'Seance Date',
          field: 'seance_date',
          type: 'string',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Seance Date',
          },
          tdClass: 'text-left',
          width: '145px',
        },
        {
          label: 'Created On',
          field: 'created_on',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Created On',
          },
          tdClass: 'text-left',
          width: '145px',
        },
        {
          label: 'Actions',
          field: 'actions',
          sortable: false,
          tdClass: 'text-right',
          width: '105px',
        },
      ],
      routeFrom: router.currentRoute.params.r,
      isSeanceSidebarActive: false,
      refetchData: '',
      filterQ: '',
      show: true,
      itemList: [],
      hasResult: true,
      seanceEditVisible: false,
      ruleSidebarWidth: '70%',
      showMoreInfoVisible: false,
      selectedRowId: '',
      selected: [],
      perPageOptions: [5, 10, 25, 50, 100],
      premiumStatusOptions: [{ text: 'Any', value: '' }, { text: 'Deleted', value: 'D' }, { text: 'Premium Granted', value: 'O' }, { text: 'Premium Rejected', value: 'R' }],
      syncStatus: '',
      syncStatusOptions: [{ text: 'Any', value: '' }, { text: 'In Sync', value: '1' }, { text: 'Out of Sync', value: '0' }],
      premiumStatus: '',
      perPage: 10,
      filter: null,
      filterOn: [],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
    cssVars() {
      return {
        '--max-width': this.ruleSidebarWidth,
      }
    },
  },
  watch: {
    isSeanceSidebarActive() {
      this.reloadPage()
    },
    currentPage() {
      this.reloadPage()
    },
    perPage() {
      this.reloadPage()
    },
  },
  beforeCreate() {
    this.premiumTypeOptions = store.getters['rules-premium-types/GET_PREMIUM_TYPES']
  },
  mounted() {
    this.loadReferencePeriods()
    this.referencePeriod = this.getCurrentReferencePeriod()
    this.reloadPage()
    this.ruleSidebarWidth = '60%'
  },
  created() {
    // eslint-disable-next-line no-unused-vars
    this.keyTyping = false
    window.addEventListener('keydown', e => {
      this.keyTyping = true
      if (e.key === 'Enter') {
        this.keyTyping = false
        // this.reloadPage()
      }
    })
  },
  methods: {
    // ${row.id}
    getCurrentReferencePeriod() {
      this.loadReferencePeriods()
      let referencePeriodSelected = 1
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < this.referencePeriodOptions.length; i++) {
        const periodStart = Date.parse(this.referencePeriodOptions[i].period_start)
        const periodEnd = Date.parse(this.referencePeriodOptions[i].period_end)
        const currentYear = Date.parse(new Date().getFullYear())

        if ((currentYear <= periodEnd && currentYear >= periodStart)) {
          referencePeriodSelected = this.referencePeriodOptions[i].value
        }
      }
      return referencePeriodSelected
    },
    loadReferencePeriods() {
      this.referencePeriodOptions = store.getters['rules-reference-periods/GET_REFERENCE_PERIODS']
    },
    onColumnFilter(params) {
      this.columnFilters = {
        seance_uid: params.columnFilters.seance_uid,
        company_id: params.columnFilters.company_id,
        trainee_id: params.columnFilters.trainee_id,
        trainee_pc: params.columnFilters.trainee_pc,
        premium_type: params.columnFilters.premium_type,
        seance_duration: params.columnFilters.seance_duration,
        seance_date: params.columnFilters.seance_date,
        created_on: params.columnFilters.created_on,
      }
      if (this.keyTyping === false) {
        this.reloadPage()
      }
    },
    onSortChange(params) {
      this.columnSort = {}
      this.columnSort.direction = params[0].type
      this.columnSort.field = params[0].field
      this.reloadPage()
      // console.log(params[0])
      // params[0].sortType - ascending or descending
      // params[0].columnIndex - index of column being sorted
    },
    syncTdClass(item) {
      const colorClass = 'tbl-row-sync-yes'
      // if (!item || type !== 'row') { return }

      // eslint-disable-next-line consistent-return
      if (item.is_sync === true) {
        return colorClass
      }
      return 'tbl-row-sync-no'
    },
    backToSeancesList() {
      router.currentRoute.params.r = ''
      router.currentRoute.params.q = ''
      this.filterQ = ''
      this.columnFilters = {}
      this.reloadPage()
    },
    formatCompany(company) {
      return company.uid
    },
    formatTrainee(trainee) {
      return trainee.uid
    },
    formatDate(date) {
      return new Date(date).toISOString().slice(0, 10)
    },
    formatDateTime(date) {
      return ` ${new Date(date).toISOString().slice(0, 10)} ${new Date(date).toISOString().slice(11, 16)} `
    },
    onRowSelected(row) {
      this.selectedRowId = row.row.id
      this.seanceEditVisible = true
    },
    onCheckboxSelected(row) {
      this.selected = row.id
    },
    resetPage() {
      router.currentRoute.params.q = ''
      router.currentRoute.params.r = ''
      this.syncStatus = ''
      this.premiumStatus = ''
      this.$router.push({ name: 'seances.search-list', params: { q: '', r: '' } }).catch(() => {})
      this.reloadPage()
    },
    reloadPage() {
      this.show = true
      // this.itemList = []
      const searchTerm = router.currentRoute.params.q
      const searchRoute = router.currentRoute.params.r
      const filterText = this.filterQ
      const searchData = {
        referencePeriod: this.referencePeriod,
        columnFilters: this.columnFilters,
        columnSort: this.columnSort,
        q: filterText,
        filterBy: searchRoute,
        search: searchTerm,
        itemsPerPage: this.perPage,
        currentPage: this.currentPage,
        syncStatus: this.syncStatus,
        premiumStatus: this.premiumStatus,
      }
      // itemList = [{'id':111}]
      const { fetchSeances } = premiumSeances()
      fetchSeances(searchData)
        .then(response => {
          if (response.data.data.total_records > 0) {
            this.hasResult = true
          } else {
            this.hasResult = false
          }
          this.itemList = response.data.data.records
          this.totalRows = response.data.data.total_records
          this.show = false
        })
    },
    onClickChild(value) {
      if (value) {
        this.reloadPage()
      } else if (value === 'more-info-bt') {
        this.ruleSidebarWidth = '100%'
        this.showMoreInfoVisible = true
      } else if (value === 'few-info-bt') {
        this.ruleSidebarWidth = '30%'
        this.showMoreInfoVisible = false
      }
    },
  },
}

</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '~vue-good-table/src/styles/style.scss';
#rule-sidebar-right {
  width: var(--max-width);
  //max-width: var(--max-width);
}
.tbl-row-status-cancelled {
  background: #f6e0e6;
}
.tbl-row-sync-yes {
  border: none;
  max-width: 1px !important;
  background: #5dde91;
  margin: 0;
  padding: 0;
}
.tbl-row-sync-no {
  border: none;
  max-width: 1px !important;
  background:#fcd61a;
  margin: 0;
  padding: 0;
}
#seanceTbl table {
  table-layout: fixed;
}
#seanceTbl .vgt-table tr td {
  vertical-align: middle;
}
#seanceTbl thead tr th:first-child,
#seanceTbl tbody tr td:first-child {
  border-right: 0 !important;
  padding: 3px !important;
}
</style>
